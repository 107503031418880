import axios from "axios";

const api = axios.create({
  //DEV
  //baseURL: 'http://localhost:80/api/',

  baseURL: "https://www.choppbot.com.br/api/",

  headers: {
    "Chopp-Token": "$10$gqUEvTBQY9zSFzjqjMSzi.Y6mmz2i94/vF/mFP3uZuUqYHR9Cia5i",
    "Access-Control-Allow-Origin": "*",
    Token: "$10$gqUEvTBQY9zSFzjqjMSzi.Y6mmz2i94/vF/mFP3uZuUqYHR9Cia5i",
  },
});

export default api;
