import "bootstrap-icons/font/bootstrap-icons.css";
import InputMask from "react-input-mask";

const CpfInput = ({
  maskType,
  maxLength,
  inputName,
  inputIcon,
  inputType,
  inputValue,
  whenChanged,
  isRequired,
}) => {
  const whenTyping = (event) => {
    whenChanged(event.target.value);
  };

  return (
    <div className="text-field">
      <i className={inputIcon}></i>

      <InputMask
        minLength={11}
        mask={maskType}
        required={isRequired}
        onChange={whenTyping}
        value={inputValue}
        placeholder={inputName}
        type={inputType}
      />
    </div>
  );
};

export default CpfInput;
