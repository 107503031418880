import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Success from './pages/Success';
import UseTerms from './pages/UseTerms';
import { BrowserRouter, Route, Routes} from "react-router-dom";
import Error from './pages/Error';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <Routes>
        {/*<Route index element={<Index />} />*/}
        <Route index path=":eventId" element={<App />} />
        <Route path="/form/success" element={<Success />} />
        {/* <Route path="use_terms" element={<UseTerms />} /> */}
        <Route path="/form/error" element={<Error />} />
      </Routes>
  </BrowserRouter>
);