import SuccessCard from '../components/SuccessCard'
import watermark from '../assets/watermark.png'

const Success = ({ navigation }) => {
    return(
        <SuccessCard 
            message="Cadastro realizado com sucesso" 
            advice="APRECIE COM MODERAÇÃO"
            watermark={watermark}
        />
    )
}

export default Success