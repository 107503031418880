import "./App.css";
import Form from "./components/Form";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingSpinner from "./components/LoadingSpinner";
import api from "./api";

function App() {
  let navigate = useNavigate();
  const { eventId } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const register = async () => {
      await api
        .get(`uncustomer/${eventId}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsLoading(false);
        })
        .catch((error) => {
          navigate("/form/error");
        });
    };

    register();
  }, [eventId, navigate]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          height: "100vh",
        }}
      >
        <LoadingSpinner
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            position: "fixed",
            background: "#000",
          }}
        />
        <br />
        <h1>Carregando</h1>
      </div>
    );
  }

  return (
    <div className="App">
      <Form eventId={eventId} />
    </div>
  );
}

export default App;
