import "./SuccessCard.css";
import image from "../../assets/success_icon.png";

const SuccessCard = ({ message, advice, watermark }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", height: "100vh" }}>
      <section className="success-card">
        <h1>{message}</h1>
        <img src={image} alt="congratulations" />
        <h3>{advice}</h3>
        <div className="watermark">
          <img src={watermark} alt="watermark" />
          <h5>All rights reserved</h5>
        </div>
      </section>
    </div>
  );
};

export default SuccessCard;
