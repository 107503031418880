export const formFieldsInfo = {
  city: {
    name: "Cidade",
    inputName: "Cidade",
    inputIcon: "bi bi-house icon",
    inputType: "text",
    minLength: 2,
    maxLength: 50,
  },
  face: {
    name: "Foto",
    inputName: "Foto",
    inputIcon: "bi bi-image icon",
    inputType: "file",
    minLength: 0,
    maxLength: 0,
  },
  name: {
    name: "Nome",
    inputName: "Nome e sobrenome",
    inputIcon: "bi bi-person icon",
    inputType: "text",
    minLength: 3,
    maxLength: 45,
  },
  email: {
    name: "Email",
    inputName: "Email",
    inputIcon: "bi bi-envelope icon",
    inputType: "email",
    minLength: 5,
    maxLength: 50,
  },
  phone: {
    name: "Telefone",
    inputName: "+55 (00) 00000-0000",
    inputIcon: "bi bi-telephone icon",
    inputType: "text",
    minLength: 11,
    maxLength: 19,
    maskType: "+55(99)99999-9999",
  },
  state: {
    name: "Estado",
    inputName: "Estado",
    inputIcon: "bi bi-buildings icon",
    inputType: "text",
    minLength: 2,
    maxLength: 22,
  },
  office: {
    name: "Setor",
    inputName: "Setor",
    inputIcon: "bi bi-person-badge icon",
    inputType: "text",
    minLength: 2,
    maxLength: 50,
  },
  company: {
    name: "Empresa",
    inputName: "Empresa",
    inputIcon: "bi bi-building icon",
    inputType: "text",
    minLength: 1,
    maxLength: 50,
  },
  identifier: {
    name: "CPF",
    inputName: "CPF",
    inputIcon: "bi bi-fingerprint icon",
    inputType: "text",
    minLength: 11,
    maxLength: 14,
    maskType: "999.999.999-99",
  },
  profession: {
    name: "Cargo",
    inputName: "Cargo",
    inputIcon: "bi bi-mortarboard icon",
    inputType: "text",
    minLength: 2,
    maxLength: 50,
  },
  client_code: {
    name: "Código de cliente",
    inputName: "Código de cliente",
    inputIcon: "bi bi-fingerprint icon",
    inputType: "text",
    minLength: 1,
    maxLength: 50,
  },
};

export const formFieldsInterface = {
  city: false,
  face: true,
  name: true,
  email: false,
  phone: false,
  state: false,
  office: false,
  company: false,
  identifier: false,
  profession: false,
  client_code: false,
};

export function orderFormFields(fields) {
  const orderedFields = {};
  const order = [
    "face",
    "name",
    "identifier",
    "phone",
    "email",
    "state",
    "city",
    "company",
    "office",
    "profession",
    "client_code",
  ];

  console.log(fields);
  // Add properties in the defined order
  order.forEach((field) => {
    if (fields.hasOwnProperty(field)) {
      orderedFields[field] = fields[field];
    }
  });

  // Add remaining properties not in the defined order
  for (const field in fields) {
    if (!order.includes(field)) {
      orderedFields[field] = fields[field];
    }
  }

  return orderedFields;
}
