import '../assets/Error.css'

const Error = () => {
    return(
        <div className='error_area'>
            <h1>Opsss</h1>
            <h2>404 - Evento não encontrado</h2>
            <h3>
                Parece que a página que você 
                procura não está disponível, 
                tente acessar o qrcode disponibilizado pelo evento.
            </h3>
        </div>
    )
}

export default Error