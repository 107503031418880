import "./TextInput.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const TextInput = ({
  minLength,
  maxLength,
  inputName,
  inputIcon,
  inputType,
  inputValue,
  whenChanged,
  isRequired,
}) => {
  const whenTyping = (event) => {
    whenChanged(event.target.value);
  };

  return (
    <div className="text-field">
      <i className={inputIcon}></i>

      <input
        minLength={minLength}
        maxLength={maxLength}
        required={isRequired}
        onChange={whenTyping}
        value={inputValue}
        placeholder={inputName}
        type={inputType}
      />
    </div>
  );
};

export default TextInput;
