import api from ".";

/**
 * Gets a choppbot event data
 * @param {string} eventToken the event link identifier
 */
export async function getEvent(eventToken) {
  return await api
    .get("event/forms", {
      headers: {
        "Event-Token": eventToken,
      },
    })
    .then((res) => res.data);
}
