import './LoadingSpinner.css'

const LoadingSpinner = () => {
    return (
        <div className="spin">
            <div className="loading-spinner"></div>
        </div>
    )
}

export default LoadingSpinner