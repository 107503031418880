import "../Checkbox/CheckBox.css";

const CheckBox = ({ hasAgreed, onChange, isUseTermsVisible }) => {
  return (
    <div className="checkbox-area">
      <input
        style={{ minWidth: "24px", minHeight: "24px", cursor: "pointer" }}
        type="checkbox"
        name="terms"
        onChange={onChange}
        checked={hasAgreed}
      />
      <label htmlFor="terms">
        Tenho +18 anos / Li e concordo com os{" "}
        <strong onClick={isUseTermsVisible}>termos de uso e consumo</strong>
      </label>
    </div>
  );
};

export default CheckBox;
