import '../assets/UseTerms.css'
import Button from '../components/Button'

const UseTerms = ({closeUseTerms}) => {
    return(
        <div className='modal-container'>
            <div className='use-terms-box'>
                <div className='terms-title'>
                    <h1>Termos de uso e consumo</h1>
                </div>
                <hr/>
                <div className='terms-content'>
                    <p>
                        <strong>Ao aceitar os termos de uso e consumo </strong> você 
                        confirma que tem <strong>18 anos ou mais </strong> e se responsabiliza 
                        por suas ações.

                        Também dá permissão para a <strong>ZIONS Robotics and Technologies</strong> utilizar seus dados 
                        para o treinamento do reconhecimento facial/melhoria da inteligência artificial.
                    </p>
                </div>
                    <Button buttonEvent={closeUseTerms}>Fechar</Button>
            </div>
        </div>
    )
}

export default UseTerms