import "./ImageInput.css";
import image from "../../assets/user.png";
import { useState } from "react";
import Resizer from "react-image-file-resizer";

const ImageInput = ({ whenChanged }) => {
  const [photo, setPhoto] = useState();

  const compressImage = (userImage) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        userImage, // the file from input
        512, // width
        512, // height
        "JPEG", // compress format WEBP, JPEG, PNG
        80, // quality
        0, // rotation
        (uri) => {
          whenChanged(uri);
          // You upload logic goes here
        },
        "file", // blob or base64 default base64
      );
    });

  const whenFileAdded = async (event) => {
    setPhoto(URL.createObjectURL(event.target.files[0])); //Display file input image
    const compressedImage = await compressImage(event.target.files[0]);
  };

  return (
    <div className="image-input">
      <div className="image-portrait">
        <img src={photo != undefined ? photo : image} alt="profile_picture" />
      </div>
      <br />
      <div className="file-input">
        <label htmlFor="photo-input">
          Carregar foto
          <input
            onChange={whenFileAdded}
            id="photo-input"
            type="file"
            accept="image/*"
            capture="user"
          />
        </label>
      </div>
    </div>
  );
};

export default ImageInput;
