import Button from "../Button";
import TextInput from "../TextInput";
import { useMemo, useEffect, useState } from "react";
import ImageInput from "../ImageInput";
import { useNavigate } from "react-router-dom";
import PhoneInput from "../TextInput/PhoneInput";
import WarningDialog from "../WarningDialog";
import api from "../../api";
import CheckBox from "../Checkbox";
import UseTerms from "../../pages/UseTerms";
import "./Form.css";
import { getEvent } from "../../api/events";
import { formFieldsInfo, orderFormFields } from "./formFields";
import CpfInput from "../TextInput/CpfInput";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

//Use Bootstrap icons https://icons.getbootstrap.com/
const Form = ({ eventId }) => {
  const navigate = useNavigate();
  const [isDialogVisible, setDialogVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [profession, setProfession] = useState("");
  const [company, setCompany] = useState("");
  const [office, setOffice] = useState("");
  const [clientCode, setClientCode] = useState("");
  const [message, setMessage] = useState("Por favor revise seus dados");
  const [photo, setPhoto] = useState(undefined);
  const [isChecked, setIsChecked] = useState(false);
  const [isUseTermsVisible, setIsUseTermsVisible] = useState(false);
  const [event, setEvent] = useState();
  const [error, setError] = useState(false);
  const formFieldsOrdered = useMemo(
    () => (event ? orderFormFields(event.form_fields) : null),
    [event]
  );

  useEffect(() => {
    async function fetchEvent() {
      await getEvent(eventId)
        .then((res) => {
          setEvent(res.data);
        })
        .catch((err) => {
          setError(true);
        });
    }

    fetchEvent();
  }, [eventId]);

  const checkOrUncheck = () => {
    if (isChecked) setIsChecked(false);
    else setIsChecked(true);
  };

  const valueHandlers = useMemo(
    () => ({
      city: {
        value: city,
        setter: setCity,
        isValid: city.length >= 2,
        invalidMessage: "Forneça um nome de cidade válido",
      },
      face: {
        value: photo,
        setter: setPhoto,
        isValid: !!photo,
        invalidMessage: "É necessário uma foto para seu cadastro",
      },
      name: {
        value: name,
        setter: setName,
        isValid: name.length >= 3,
        invalidMessage: "Seu nome deve ter 3 ou mais caracteres",
      },
      email: {
        value: email,
        setter: setEmail,
        isValid: !!validateEmail(email),
        invalidMessage: "Forneça um endereço de email válido",
      },
      phone: {
        value: telephone,
        setter: setTelephone,
        isValid: !(telephone.slice(-1) === "_" || telephone.slice(-1) === ""),
        invalidMessage: "Preencha seu telefone corretamente",
      },
      state: {
        value: state,
        setter: setState,
        isValid: state.length >= 2,
        invalidMessage: "Forneça um estado válido",
      },
      company: {
        value: company,
        setter: setCompany,
        isValid: company.length > 0,
        invalidMessage: "Forneça um nome de empresa válido",
      },
      office: {
        value: office,
        setter: setOffice,
        isValid: office.length >= 2,
        invalidMessage: "Forneça um setor válido",
      },
      identifier: {
        value: identifier,
        setter: setIdentifier,
        isValid: identifier.length === 11 || identifier.length === 14,
        invalidMessage: "CPF inválido",
      },
      profession: {
        value: profession,
        setter: setProfession,
        isValid: profession.length >= 2,
        invalidMessage: "Forneça um cargo válido",
      },
      client_code: {
        value: clientCode,
        setter: setClientCode,
        isValid: clientCode.length >= 1,
        invalidMessage: "Forneça um código de cliente válido",
      },
    }),
    [
      photo,
      name,
      city,
      email,
      telephone,
      company,
      office,
      profession,
      state,
      clientCode,
      identifier,
    ]
  );

  const onSave = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const formFieldsEntries = Object.entries(formFieldsOrdered);

    let hasInvalidField = false;

    for (let index = 0; index < formFieldsEntries.length; index++) {
      const [key, shouldShow] = formFieldsEntries[index];
      const valueHandler = valueHandlers[key];
      if (shouldShow) {
        if (!valueHandler.isValid) {
          console.log(valueHandler.value);
          setMessage(valueHandler.invalidMessage);
          setDialogVisibility(true);
          hasInvalidField = true;
          break;
        }
      }
    }

    if (!hasInvalidField && !isChecked) {
      setMessage("Aceite os termos de uso e consumo");
      setDialogVisibility(true);
    }
    if (hasInvalidField || !isChecked) {
      window.scrollTo(0, 0);
      setIsLoading(false);
      return;
    }

    // Clearing error messages
    setMessage("");
    setDialogVisibility(false);

    //Deu certo
    const formData = new FormData();
    formData.append("link", eventId);
    formData.append("face", photo);
    formData.append("name", name);
    if (identifier) formData.append("identifier", identifier);
    if (telephone) formData.append("phone", telephone);
    if (email) formData.append("email", email);
    if (state) formData.append("state", state);
    if (city) formData.append("city", city);
    if (company) formData.append("company", company);
    if (office) formData.append("office", office);
    if (profession) formData.append("profession", profession);
    if (clientCode) formData.append("client_code", clientCode);

    //compressPhoto()
    await register(formData);
    setDialogVisibility(false);

    window.scrollTo(0, 0);
    setIsLoading(false);
  };

  function showUseTerms() {
    if (isUseTermsVisible) {
      setIsUseTermsVisible(false);
    } else {
      setIsUseTermsVisible(true);
    }
  }

  let register = async (formData) => {
    let response = await api
      .post("uncustomer", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        //Called when a good answer is returned
        navigate("/form/success");
      })
      .catch(({ response }) => {
        //Called when a erro is returned
        setDialogVisibility(true);
        if (response.status === 412) {
          setMessage("Tire uma foto de seu rosto");
        }
        if (response.status === 404) {
          setMessage("Comanda já cadastrada");
        }
        setIsLoading(false);
      });
    console.log(response);
  };

  return (
    <>
      {isDialogVisible && <WarningDialog message={message} />}
      <form
        className="default-form"
        onSubmit={onSave}
        encType="multipart/form-data"
      >
        <h1
          style={{
            textAlign: "center",
            fontSize: "xx-large",
            marginBottom: "20px",
          }}
        >
          Cadastro Choppbot
        </h1>
        <ImageInput
          whenChanged={(value) => {
            if (value) setPhoto(value);
          }}
          inputValue={photo}
        />
        <br />
        {formFieldsOrdered &&
          Object.entries(formFieldsOrdered).map(([key, shouldShow]) => {
            const fieldInfo = formFieldsInfo[key];
            const valueHandler = valueHandlers[key];
            if (shouldShow) {
              if (key === "face") return null;
              if (key === "phone")
                return (
                  <PhoneInput
                    key={key}
                    isRequired={false}
                    minLength={fieldInfo.minLength}
                    maxLength={fieldInfo.maxLength}
                    maskType={fieldInfo.maskType}
                    inputValue={telephone}
                    whenChanged={(value) => setTelephone(value)}
                    inputName={fieldInfo.inputName}
                    inputIcon={fieldInfo.inputIcon}
                    inputType={fieldInfo.inputType}
                  />
                );
              if (key === "identifier")
                return (
                  <CpfInput
                    key={key}
                    isRequired={false}
                    minLength={fieldInfo.minLength}
                    maxLength={fieldInfo.maxLength}
                    maskType={fieldInfo.maskType}
                    inputValue={identifier}
                    whenChanged={(value) => setIdentifier(value)}
                    inputName={fieldInfo.inputName}
                    inputIcon={fieldInfo.inputIcon}
                    inputType={fieldInfo.inputType}
                  />
                );
              return (
                <TextInput
                  key={key}
                  isRequired={false}
                  minLength={fieldInfo.minLength}
                  maxLength={fieldInfo.maxLength}
                  inputValue={valueHandler.value}
                  whenChanged={(value) => valueHandler.setter(value)}
                  inputName={fieldInfo.inputName}
                  inputIcon={fieldInfo.inputIcon}
                  inputType={fieldInfo.inputType}
                />
              );
            }
            return null;
          })}
        <CheckBox
          isUseTermsVisible={showUseTerms}
          hasAgreed={isChecked}
          onChange={checkOrUncheck}
        />
        <Button disabled={isLoading}>Enviar</Button>
      </form>
      {isUseTermsVisible && <UseTerms closeUseTerms={showUseTerms} />}
    </>
  );
};

export default Form;
